import {combineReducers } from "redux"
import { configureStore} from '@reduxjs/toolkit';
import VisitorSlice from "../ReduxSlice/VisitorSlice";
import VisitorCompSlice, { setVisitorComp } from '../ReduxSlice/CompSlice'
import VisitorInfoSlice, { setVisitorInfo } from "../ReduxSlice/VisitorInfoSlice";
import NavigationSlice from "../ReduxSlice/CommonSlice.js/NavigationSlice";
import toastSlice from "../ReduxSlice/CommonSlice.js/toastSlice";

const storeConfigure = () => {
    const rootReducer = combineReducers({
        visitorDetails:VisitorSlice,
      visitorComp: VisitorCompSlice,
      visitorInfo: VisitorInfoSlice,
      navigation: NavigationSlice,
      toastSlice:toastSlice
    })
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
      });
    return store
}

export default storeConfigure;
