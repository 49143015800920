import axios from 'axios';

const setHeaders = () => {
    let headers = {};
    let token = localStorage.getItem('token');
    headers['Content-Type'] = 'application/json';
    if (token) {
        headers['Authorization'] = localStorage.getItem('token');
    }
    headers['x-csrf-token'] = localStorage.getItem('csrfToken');
    return headers;
};

const setConfigObj = () => {
    let config = {}
    config.headers = setHeaders()

    config.withCredentials = true

    return config
}

export const POST = (endpoint, body,url, showCsrfError) => {
    return new Promise(async function (resolve, reject) {
        // console.log('urlllll',{url,body})
        try {
            const response = await axios.post((url ? url : process.env.REACT_APP_VISITOR_MANAGEMENT) + endpoint, body, setConfigObj())
            resolve(response)
        } catch (e) {
            if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
            console.log(e);
            reject(e);
        }
    });
};

export const FILEPOST = (endpoint, body, showCsrfError) => {
    const headers = {
        'content-type': 'multipart/form-data',
        'Authorization': localStorage.getItem('token'),
        'x-csrf-token': localStorage.getItem('csrfToken')
    }
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.post(process.env.REACT_APP_VISITOR_MANAGEMENT + endpoint, body, { headers, withCredentials: true })
            resolve(response)
        } catch (e) {
            if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
            console.log(e);
            reject(e);
        }
    });
};


export const PUT = (endpoint, body, showCsrfError) => {

    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.put(process.env.REACT_APP_VISITOR_MANAGEMENT + endpoint, body, setConfigObj())
            resolve(response);
        } catch (e) {
            if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
            console.log(e);
            reject(e);
        }
    });
};

export const GET = (endpoint, decodedToken) => {
    //  const url = 'http://192.168.100.74:8020/'
    const setGetHeaders = () => {
        let headers = {};
        let token = localStorage.getItem('token');
        headers['Content-Type'] = 'application/json';
        if (token) {
            headers['Authorization'] = token;
        }
        else {
            headers['Authorization'] = decodedToken
        }
        return headers;
    };
    let headers = setGetHeaders();
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.get((  process.env.REACT_APP_VISITOR_MANAGEMENT) + endpoint, { headers })
            resolve(response);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
};

export const DELETE = (endpoint, showCsrfError) => {

    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.delete(process.env.REACT_APP_VISITOR_MANAGEMENT + endpoint, setConfigObj())
            resolve(response);
        } catch (e) {
            if (e?.response?.status === 400 && e?.response?.data?.error === "Invalid csrf token") GET_CSRF()
            console.log(e);
            reject(e);
        }
    });
};

export const GET_CSRF = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_VISITOR_MANAGEMENT}v1/csrf`, { withCredentials: true })
        if (response?.status === 200) {
            localStorage.setItem('csrfToken', response?.data?.csrf_token)
        }
    } catch (error) {
        console.log(error)
    }
}

